import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { apiHandle } from "../../hooks/services/apiServices";
import { isPostErrorResult, toastErr } from "../../utils/utils";

import { Container, Box, Button, TextField, Toolbar } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { Search } from "@mui/icons-material";
import LoadingButton from '@mui/lab/LoadingButton';


export const OperationSearchPage = ({ setTitle, setBackToPath }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchType, setSearchType] = useState();
  const [searchTitle, setSearchTitle] = useState();
  const [searchDesc, setSearchDesc] = useState();
  const [isPekkong, setPekkong] = useState(false);
  const [isLoadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setTitle(t("menu.item.operation"));
    setBackToPath({ pathName: "/operation" });

    if (location.state?.searchTitle && location.state?.searchType) {
      setSearchTitle(location.state.searchTitle);
      setSearchType(location.state.searchType);

      switch (location.state.searchType) {
        case "resend":
          setSearchDesc(t("operation.search.resend"));
          break;
        case "edit":
          setSearchDesc(t("operation.search.resend"));
          break;
        case "void":
          setSearchDesc(t("operation.search.resend"));
          break;
        case "pekkong":
          setSearchDesc(t("operation.search.pekkong"));
          setPekkong(true);
          break;
        default:
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingButton(true);
    const data = new FormData(event.currentTarget);
    const invoiceno = data.get("invoiceno");

    switch (searchType) {
      case "resend":
      case "void":
        apiHandle.getHistorySearch({
          token: user.accessToken,
          searchType: "invoiceno",
          searchNumber: invoiceno
        }).then(result => {
          setLoadingButton(false);

          if (isPostErrorResult(result.data)) {
            toastErr(result.data);
          } else {
            navigate("/operation/search-result", {
              state: {
                searchTitle: searchTitle,
                searchType: searchType,
                searchResult: result.data,
                invoiceno: invoiceno,
                action: "CANCELLED"
              }
            });
          }
        }).catch(e => {
          setLoadingButton(false);
          toastErr(e);
        });
        break;
      case "edit":
        apiHandle.editOrder({
          token: user.accessToken,
          invoiceno: invoiceno
        }).then(result => {
          setLoadingButton(false);
          
          if (isPostErrorResult(result.data)) {
            toastErr(result.data);
          } else {
            navigate("/operation/search-result", {
              state: {
                searchTitle: searchTitle,
                searchType: searchType,
                searchResult: result.data,
                action: "EDIT"
              }
            });
          }
        }).catch(e => {
          setLoadingButton(false);
          toastErr(e);
        });
        break;
      case "pekkong":
        apiHandle.getPekkongSearch({
          token: user.accessToken,
          name: invoiceno, // name
          number: data.get("number")
        }).then(result => {
          setLoadingButton(false);
          
          if (isPostErrorResult(result.data)) {
            toastErr(result.data);
          } else {
            navigate("/operation/search-result", {
              state: {
                searchTitle: searchTitle,
                searchType: searchType,
                searchResult: result.data,
                action: "CANCLLED"
              }
            });
          }
        }).catch(e => {
          setLoadingButton(false);
          toastErr(e);
        });
        break;
      default:
        break;
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box component="form" onSubmit={handleSubmit} sx={{ marginTop: 3, flexGrow: 1 }} noValidate>
        <Toolbar disableGutters>
          <TextField
            autoFocus
            name="invoiceno"
            sx={{ flexGrow: 1 }}
          />
          { isPekkong &&
            <TextField
              name="number"
              sx={{ flexGrow: 1 }}
            />
          }
          {isLoadingButton ? (
            <LoadingButton
              fullWidth
              loading
              loadingPosition="start"
              variant="outlined"
              startIcon={<Search />}
              sx={{ flexGrow: 1, padding: '15px 0 15px 0', marginLeft: 1 }}
            >
              {t("button.label.search")}
            </LoadingButton>
          ) : (
            <Button
              fullWidth
              type="submit"
              sx={{ flexGrow: 1, padding: '15px 0 15px 0', marginLeft: 1 }}
              variant="contained"
              startIcon={<Search />}
            >
              {t("button.label.search")}
            </Button>
          )}
        </Toolbar>
        <Alert severity="info" sx={{ marginTop: 2 }}>
          <AlertTitle>{searchTitle}</AlertTitle>
          {searchDesc}
        </Alert>
      </Box>
    </Container>
  );
};
import { useEffect } from "react";
import { MenuIconPage } from "../components/MenuIconPage";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AppsIcon from '@mui/icons-material/Apps';

const APP_AGENT = process.env.REACT_APP_AGENT;

export const OrderMenuPage = ({ setTitle, setBackToPath }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (APP_AGENT !== "BR") {
      setTitle(t("menu.item.order"));
      setBackToPath({ pathName: "/dashboard" });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    APP_AGENT === "BR" ? <Navigate to="/order/advanced" replace /> :
    <MenuIconPage pages={[
      { label: t("order.menu.advanced"), path: "/order/advanced", icon: <AppRegistrationIcon color="primary"/> },
      { label: t("order.menu.simple"), path: "/order/simple", icon: <AppsIcon color="primary"/> },
    ]} />
  );
};

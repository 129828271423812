import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { apiHandle } from "../../hooks/services/apiServices";
import { isPostErrorResult, getEditOrderText, toastErr } from "../../utils/utils";

import { Container, Box, Button, TextField, Toolbar, CircularProgress } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import LoadingButton from '@mui/lab/LoadingButton';

const APP_AGENT = process.env.REACT_APP_AGENT;

export const AdvancedOrderPage = ({ setTitle, setBackToPath }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const orderTextRef = useRef();
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [isProgressing, setProgressing] = useState(false);
  const [orderTextValue, setOrderTextValue] = useState("D\n");
  const [playerList, setPlayerList] = useState();
  const [selectedPlayer, setSelectedPlayer] = useState("");

  useEffect(() => {
    setTitle(t("menu.item.order"));
    
    if (APP_AGENT === "BR") setBackToPath({ pathName: "/dashboard" });
    else setBackToPath({ pathName: "/order" });
    
    if (location.state?.result) {
      setOrderTextValue(getEditOrderText(location.state.result));
      if (user.status === "A") {
        const result = location.state.result.split(",");
        setSelectedPlayer(result[0].replace("@", ""));
      }
    } else if (user.status === "A") {
      setProgressing(true);
      setOrderTextValue("");

      apiHandle.getPlayerList({
        token: user.accessToken
      }).then(result => {
        if (result.data && result.data !== "") {
          if (isPostErrorResult(result.data)) {
            toastErr(result.data);
          } else {
            const list = result.data.split(",");
            let resultList = [];
            
            list.forEach(item => {
              if (item && item.length > 0) {
                const playerInfo = item.split("@");
                resultList.push({label: playerInfo[0] + " " + playerInfo[1], value: playerInfo[1]});
              }
            });
            setPlayerList(resultList);
          }
        } else toastErr(t("error.player.list.empty"));
        setProgressing(false);

      }).catch(e => {
        setProgressing(false);
        toastErr(e);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingButton(true);
    const data = new FormData(event.currentTarget);

    apiHandle.order({
      token: user.accessToken,
      orderText: data.get("orderText")
    }).then(result => {
      if (result.data && result.data !== "") {
        if (isPostErrorResult(result.data)) {
          toastErr(result.data);
        } else {
          let resultArr = result.data.split(";");

          navigate("/order/result", {
            state: {
              fromPath: "/order/advanced",
              result: resultArr[0],
              invoiceno: resultArr[1]
            }
          });
        }
      } else toastErr(t("error.common.app"));
      setLoadingButton(false);
    }).catch(e => {
      setLoadingButton(false);
      toastErr(e);
    });
  };

  const handleReset = () => {
    setSelectedPlayer("");
    if (user.status && user.status === "A") {
      setOrderTextValue("");
    } else setOrderTextValue("D\n");
    orderTextRef.current.focus();
  }

  const hanhandleChangePlayer = (event) => {
    if (orderTextValue === "") setOrderTextValue("@" + event.target.value + "\n");
    else setOrderTextValue(orderTextValue.replace(selectedPlayer, event.target.value));
    setSelectedPlayer(event.target.value);
    orderTextRef.current.focus();
  }

  return (
    <Container component="main" maxWidth="xs">
      { isProgressing ?
        <Box sx={{ marginTop: 3, flexGrow: 1, textAlign: "center" }}>
          <CircularProgress />
        </Box>
      :
        <Box component="form" onSubmit={handleSubmit} sx={{ marginTop: 1, flexGrow: 1 }} noValidate>
          <Toolbar disableGutters>
            <Button
              fullWidth
              type="reset"
              onClick={handleReset}
              sx={{ flexGrow: 1, marginRight: '5px' }}
              variant="outlined"
              startIcon={<RotateLeftIcon />}
            >
              {t("button.label.reset")}
            </Button>
            {isLoadingButton ? (
              <LoadingButton
                fullWidth
                loading
                loadingPosition="end"
                endIcon={<SendIcon />}
                variant="outlined"
                sx={{ flexGrow: 1 }}
              >
                {t("button.label.loading")}
              </LoadingButton>
            ) : (
              <Button
                fullWidth
                type="submit"
                sx={{ flexGrow: 1 }}
                variant="contained"
                color="success"
                endIcon={<SendIcon />}
              >
                {t("button.label.order")}
              </Button>
            )}
          </Toolbar>
          {user.status === "A" && playerList &&
            <FormControl fullWidth sx={{ marginBottom: 1 }}>
              <InputLabel id="demo-simple-select-label">{t("form.label.select.player")}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPlayer}
                label={t("form.label.select.player")}
                onChange={hanhandleChangePlayer}
              >
                {playerList.map((item, index) => (
                  <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          <TextField
            fullWidth
            multiline
            autoFocus
            name="orderText"  
            value={orderTextValue}
            onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
            inputRef={orderTextRef}
            onChange={(e) => setOrderTextValue(e.target.value)}
            sx={{ marginBottom: 2 }}
            inputProps={{ inputMode: APP_AGENT === "BR" ? "text" : "tel" }}
          />
        </Box>
      }
    </Container>
  );
};
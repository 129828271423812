import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { LoginPage } from "./pages/Login";
import { UserAccountsPage } from "./pages/UserAccounts";
import { DashboardPage } from "./pages/Dashboard";
import { OrderMenuPage } from "./pages/Order";
import { AdvancedOrderPage } from "./pages/order/AdvancedOrder";
import { SimpleOrderPage } from "./pages/order/SimpleOrder";
import { OrderResultPage } from "./pages/order/OrderResult";
import { HistoryMenuPage } from "./pages/History";
import { HistorySearchPage } from "./pages/history/HistorySearch";
import { HistorySearchResultPage } from "./pages/history/HistorySearchResult";
import { HistoryOrderResultPage } from "./pages/history/HistoryOrderResult";
import { WinlosePage } from "./pages/Winlose";
import { WinloseResultPage } from "./pages/winlose/WinloseResult";
import { DrawPage } from "./pages/Draw";
import { DrawResultPage } from "./pages/draw/DrawResult";
import { OperationMenuPage } from "./pages/Operation";
import { OperationSearchPage } from "./pages/operation/OperationSearch";
import { OperationSearchResultPage } from "./pages/operation/OperationSearchResult";
import { ChangePasswordPage } from "./pages/operation/ChangePassword";
import { AutoTicketPage } from "./pages/operation/AutoTicket";
import { NoticePage } from "./pages/Notice";
import { HelpPage } from "./pages/Help";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { HomeLayout } from "./components/HomeLayout";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import './i18n/config';
import "./styles.css";
import 'react-toastify/dist/ReactToastify.css';

import Dashboard from "@mui/icons-material/Dashboard";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HistoryIcon from '@mui/icons-material/History';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LogoutIcon from '@mui/icons-material/Logout';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import HelpIcon from '@mui/icons-material/Help';

export default function App() {
  const [title, setTitle] = useState();
  const [backToPath, setBackToPath] = useState();
  const { t } = useTranslation();
  const pages = [
    { label: t("menu.item.dashboard"), path: "/dashboard", icon: <Dashboard color="primary"/> },
    { label: t("menu.item.order"), path: "/order", icon: <AddCircleOutlineIcon color="primary"/> },
    { label: t("menu.item.history"), path: "/history", icon: <HistoryIcon color="primary"/> },
    { label: t("menu.item.winlose"), path: "/winlose", icon: <EmojiEventsIcon color="primary"/> },
    { label: t("menu.item.result"), path: "/draw", icon: <AssessmentIcon color="primary"/> },
    { label: t("menu.item.operation"), path: "/operation", icon: <BuildCircleIcon color="primary"/> },
    { label: t("menu.item.notice"), path: "/notice", icon: <AnnouncementIcon color="primary"/> },
    { label: t("menu.item.help"), path: "/help", icon: <HelpIcon color="primary"/> },
    { label: t("menu.item.logout"), path: "logout", icon: <LogoutIcon color="secondary"/> },
  ];

  return (
    <>
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
        </Route>

        <Route path="/" element={<ProtectedLayout pages={pages} title={title} backToPath={backToPath} />}>
          <Route path="user-accounts" element={<UserAccountsPage setTitle={setTitle} />} />
          <Route path="dashboard" element={<DashboardPage pages={pages} setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="order" element={<OrderMenuPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="order/advanced" element={<AdvancedOrderPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="order/simple" element={<SimpleOrderPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="order/result" element={<OrderResultPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="history" element={<HistoryMenuPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="history/search" element={<HistorySearchPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="history/search-result" element={<HistorySearchResultPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="history/search-order" element={<HistoryOrderResultPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="winlose" element={<WinlosePage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="winlose/result" element={<WinloseResultPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="draw" element={<DrawPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="draw/result" element={<DrawResultPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="operation" element={<OperationMenuPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="operation/search" element={<OperationSearchPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="operation/search-result" element={<OperationSearchResultPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="operation/change-pwd" element={<ChangePasswordPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="operation/auto-ticket" element={<AutoTicketPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="notice" element={<NoticePage setTitle={setTitle} setBackToPath={setBackToPath} />} />
          <Route path="help" element={<HelpPage setTitle={setTitle} setBackToPath={setBackToPath} />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { MenuIconPage } from "../components/MenuIconPage";

export const DashboardPage = ({pages, setTitle, setBackToPath}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setTitle(t("menu.item.dashboard"));
    setBackToPath();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MenuIconPage pages={pages} />;
};

import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { apiHandle } from "./services/apiServices";
import CryptoJS from "crypto-js";
import { toastErr } from "../utils/utils";

const AuthContext = createContext();
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const APP_KEY = process.env.REACT_APP_KEY;
const APP_AGENT = process.env.REACT_APP_AGENT;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  const login = async (data, errorCallback) => {
    if (APP_AGENT === "BR") loginBr(data, errorCallback);
    else loginHwin(data, errorCallback);
  };

  const loginMidlet = async (data) => {
    const token = CryptoJS.AES.decrypt(data.accessToken, APP_KEY);
    const userInfo = JSON.parse(token.toString(CryptoJS.enc.Utf8));
    const requestData = "loginMidlet4," + userInfo.id + "," + userInfo.key;
    
    apiHandle.userLoginMidlet({
      reqEP: userInfo.reqEP,
      content: requestData
    }).then(result => {
      if (result.data.includes("Success")) {
        const text = result.data.split(",");
        const payload = JSON.stringify({
          id: userInfo.id,
          name: text[1],
          type: "P",
          key: userInfo.key,
          reqEP: userInfo.reqEP
        });

        setUser({
          ...user,
          gameType: data.gameType,
          informat: text[3],
          accessToken: CryptoJS.AES.encrypt(payload, APP_KEY).toString(),
          status: "P"
        });
        
        navigate("/dashboard", { replace: true });

      } else toastErr(result.data);
    }).catch(e => {
      toastErr(e);
    });
  };

  const logout = () => {
    setUser(null);
    navigate("/login", { replace: true });
  };

  const logoutMidlet = () => {
    setUser({
      ...user,
      gameType: undefined,
      accessToken: undefined,
      status: undefined,
      informat: undefined
    });
    navigate("/user-accounts");
  }

  const value = useMemo(() => ({
    user,
    login,
    loginMidlet,
    logout,
    logoutMidlet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [user]);

  const loginBr = async (data, errorCallback) => {
    apiHandle.userActivation({
      loginId: data.user,
      content: data.password
    }).then(r1 => {
      if (r1.status === 200 && r1.data.includes("Success")) {
        let text = r1.data.split(",");
        
        apiHandle.userLogin({
          loginId: text[1],
          content: data.password
        }).then(r2 => {
          if (r2.status === 200 && r2.data.includes("Success")) {
            const payload = JSON.stringify({
              id: text[1],
              name: data.user,
              type: text[4],
              key: data.password
            });
            // encrypt
            const accessToken = CryptoJS.AES.encrypt(payload, APP_KEY).toString();
            
            setUser({
              accessToken,
              status: text[4]
            });
            navigate("/dashboard", { replace: true });

          } else errorCallback({
            status: "FAILED",
            message: "FAILED"
          });
        }).catch(e => {
          errorCallback({
            status: "ERROR",
            message: e
          });
        });
      } else errorCallback({
        status: "FAILED",
        message: "FAILED"
      });
    }).catch(e => {
      errorCallback({
        status: "ERROR",
        message: e
      });
    });
  }

  const loginHwin = async (data, errorCallback) => {
    apiHandle.userActivationDual({
      loginId: data.user,
      content: data.password
    }).then(r1 => {
      if (r1.status === 200 && r1.data.includes("Success")) {
        let text = r1.data.split(",");
        
        apiHandle.userLoginDual({
          loginId: text[1],
          content: data.password
        }).then(r2 => {
          if (r2.status === 200 && r2.data.includes("Success")) {
            if (r2.data.includes("@")) {
              const loginList = r2.data.replace("Success", "MKTSWBD," + API_ENDPOINT + "," + text[1] + "," + data.password).split("@");
              let userAccounts = [];
              
              loginList.forEach(element => {
                const account = element.split(",");
                
                let requestEndPoint = "http://" + account[1] + "/servlet/servlet.MainServlet";
                if (account[0] === "MKTSWBD") requestEndPoint = "http://" + account[1];

                const payload = JSON.stringify({
                  id: account[2],
                  key: account[3],
                  name: account[4],
                  type: "P",
                  reqEP: requestEndPoint
                });

                let imgUrl = "http://" + account[1] + "/images/icon_jaguar3.png";
                if (account[0] === "MKTSWBD") {
                  imgUrl = "/assets/images/ic_mkt.png";
                }
                userAccounts.push({
                  gameType: account[0],
                  image: imgUrl,
                  accessToken: CryptoJS.AES.encrypt(payload, APP_KEY).toString()
                });
              });
              
              setUser({
                userAccounts
              });
              navigate("/user-accounts", { replace: true });

            } else {
              const payload = JSON.stringify({
                id: text[1],
                name: data.user,
                type: "P",
                key: data.password
              });
              // encrypt
              const accessToken = CryptoJS.AES.encrypt(payload, APP_KEY).toString();
              
              setUser({
                accessToken,
                informat: text[4],
                status: "P"
              });
              navigate("/dashboard", { replace: true });
            }
          } else errorCallback({
            status: "FAILED",
            message: "FAILED"
          });
        }).catch(e => {
          errorCallback({
            status: "ERROR",
            message: e
          });
        });
      } else errorCallback({
        status: "FAILED",
        message: "FAILED"
      });
    }).catch(e => {
      errorCallback({
        status: "ERROR",
        message: e
      });
    });
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ShareButton from "../../components/ShareButton";
import { Container, Box, TextField, Toolbar } from "@mui/material";

export const DrawResultPage = ({ setTitle, setBackToPath }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [resultContent, setResultContent] = useState();

  useEffect(() => {
    setTitle(t("menu.item.result"));
    setBackToPath({ pathName: "/draw" });

    if (location.state?.searchResult) {
      setResultContent(location.state.searchResult);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 1, flexGrow: 1 }}>
        <Toolbar disableGutters>
          <ShareButton content={resultContent}/>
        </Toolbar>
        <TextField
          fullWidth
          multiline
          type="text"
          value={resultContent}
          sx={{ marginBottom: 2 }}
          inputProps={
            { readOnly: true, }
          }
        />
      </Box>
    </Container>
  );
};
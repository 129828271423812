import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { apiHandle, verifyPassword } from "../../hooks/services/apiServices";
import { toastErr, isPostErrorResult } from "../../utils/utils";

import ShareButton from "../../components/ShareButton";
import { Container, Box, Button, TextField, Toolbar, Menu, MenuItem, ListItemIcon } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import LoadingButton from '@mui/lab/LoadingButton';

export const OrderResultPage = ({ setTitle, setBackToPath }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [result, setResult] = useState();
  const [invoiceno, setInvoiceno] = useState();
  const [password, setPassword] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPwdDialogOpen, setPwdDialogOpen] = useState(false);
  const [isCancelOrder, setCancelOrder] = useState(false);
  const [isLoadingButton, setLoadingButton] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setTitle(t("menu.item.order"));
    setBackToPath({ pathName: location.state.fromPath });
    if (location.state?.result) setResult(location.state.result);
    if (location.state?.invoiceno) setInvoiceno(location.state.invoiceno);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = (option) => {
    setAnchorEl(null);
    setSelectedOption(option);

    switch(option) {
      case "resend": // resend
        setPwdDialogOpen(true);
        break;
      case "edit": // edit
        apiHandle.editOrder({
          token: user.accessToken,
          invoiceno: invoiceno
        }).then(result => {
          if (result.data) {
            navigate("/order/advanced", {
              state: {
                result: result.data,
              }
            });
          } else toastErr(t("error.common.app"));
        }).catch(e => {
          toastErr(e);
        });
        break;
      case "void": // void
        setPwdDialogOpen(true);
        break;
      default:
        break;
    }
  }

  const handlePwdDialogClose = () => {
    setPwdDialogOpen(false);
    setPassword();
  }

  const handleSubmitVoid = () => {
    if (verifyPassword({
      token: user.accessToken,
      password: password
    })) {
      setLoadingButton(true);

      if (selectedOption === "void") {
        apiHandle.cancelOrder({
          token: user.accessToken,
          invoiceno: invoiceno
        }).then(result => {
          setPwdDialogOpen(false);
          setLoadingButton(false);

          if (isPostErrorResult(result.data)) {
            toastErr(result.data);
          } else {
            setResult(result.data);
            setCancelOrder(true);
          }
        }).catch(e => {
          setLoadingButton(false);
          toastErr(e);
        });
      } else if (selectedOption === "resend") {
        apiHandle.resendOrder({
          token: user.accessToken,
          invoiceno: invoiceno
        }).then(result => {
          setPwdDialogOpen(false);
          setLoadingButton(false);

          if (isPostErrorResult(result.data)) {
            toastErr(result.data);
          } else {
            setResult(result.data);
            setCancelOrder(true);
          }
        }).catch(e => {
          setLoadingButton(false);
          toastErr(e);
        });
      }
    } else toastErr(t("error.password.invalid"));
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 1, flexGrow: 1 }} noValidate>
        <Toolbar disableGutters>
          <ShareButton content={result}/>
          { !isCancelOrder && (
            <>
              <Button
                id="basic-button"
                type="button"
                sx={{ flexGrow: 1, marginLeft: 1 }}
                variant="contained"
                color="primary"
                endIcon={<SettingsSuggestIcon />}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                {t("button.label.options")}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                sx={{ flexGrow: 1 }}
              >
                <MenuItem onClick={() => handleClose("resend")}>
                  <ListItemIcon><ScheduleSendIcon fontSize="small" /></ListItemIcon>{t("option.label.resend")}
                </MenuItem>
                <MenuItem onClick={() => handleClose("edit")}>
                  <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>{t("option.label.edit")}
                </MenuItem>
                <MenuItem onClick={() => handleClose("void")}>
                  <ListItemIcon><CancelIcon fontSize="small" /></ListItemIcon>{t("option.label.void")}
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
        <TextField
          fullWidth
          multiline
          type="text"
          name="orderText"
          value={result}
          inputProps={
            { readOnly: true, }
          }
        />
      </Box>

      <Dialog open={isPwdDialogOpen} onClose={handlePwdDialogClose}>
        <DialogTitle>{selectedOption === "void" ? t("option.label.void") : t("option.label.resend")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedOption === "void" ? t("dialog.password.void.desc") : t("dialog.password.resend.desc")}
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePwdDialogClose} color="secondary">{t("button.label.cancel")}</Button>
          {isLoadingButton ? (
            <LoadingButton
              loading
              variant="outlined"
            >
              {selectedOption === "void" ? t("button.label.void") : t("button.label.resend")}
            </LoadingButton>
          ) : (
            <Button onClick={handleSubmitVoid} color="error" variant="contained">
              {selectedOption === "void" ? t("button.label.void") : t("button.label.resend")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
}
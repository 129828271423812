import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ShareDialog from './ShareDialog';
import { Button } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';

const ShareButton = ({content}) => {
  const { t } = useTranslation();
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);

  return(
    <>
      <Button
        type="button"
        sx={{ flexGrow: 1 }}
        variant="contained"
        color="success"
        startIcon={<ShareIcon />}
        onClick={() => setShareDialogOpen(true)}
      >
        {t("button.label.share")}
      </Button>
      <ShareDialog content={content} isShareDialogOpen={isShareDialogOpen} setShareDialogOpen={setShareDialogOpen} />
    </>
  )
}
export default ShareButton;
import axios from "axios";
import { toastErr } from "../../utils/utils";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const defaultHeader = {
    "Content-Type": "text/plain"
};

const client = axios.create({
    //baseURL: "http://" + API_ENDPOINT,
    withCredentials: false
});
client.defaults.timeout = 10000;

class DataService {

    static post(data = "", requestEndPoint) {
        if (requestEndPoint && requestEndPoint !== undefined) client.defaults.baseURL = requestEndPoint;
        else client.defaults.baseURL = "http://" + API_ENDPOINT;

        return client({
            method: "POST",
            body: data,
            data,
            header: defaultHeader,
        });
    }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
    // do something before executing the request
    // For example tag along the bearer access token to request header or set a cookie
    const requestConfig = config;
    const { headers } = config;
    requestConfig.headers = { ...headers };
    return requestConfig;
});

client.interceptors.response.use(
    response => response,
    error => {
        /**
         * Do something in case the response returns an error code [3**, 4**, 5**] etc
         * For example, on token expiration retrieve a new access token, retry a failed request etc
         */
        const { response } = error;
        const originalRequest = error.config;
        let pathname = window.location.pathname;
        console.log('error', error);
        if (error.message && (error.message === 'Network Error' || error.message.includes('timeout'))) {
            if (pathname === '/login') {
                toastErr('Network Error');
            } else 
                window.location.href = "/not-found";
        }
        
        if (response) {
            if (response.status === 500) {
                // do something here
                
            } else {
                return originalRequest;
            }
        }
        return Promise.reject(error);
    }
);
export { DataService };

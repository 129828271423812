
import { toast } from "react-toastify";

export const isPostErrorResult = (result) => {
  return result.includes("Exception") || result.includes("Err") || result.includes("Sorry") || result.includes("Announcement") || result.includes("contact admin")
}

export const getEditOrderText = (text) => {
  return text.replaceAll(",", "\n");
}

export const convertCounter = (counter) => {
  let counterNumber = "";
  if (counter.includes("M")) counterNumber += "1";
  if (counter.includes("K")) counterNumber += "2";
  if (counter.includes("T")) counterNumber += "3";
  if (counter.includes("S")) counterNumber += "4";
  if (counter.includes("W")) counterNumber += "5";
  if (counter.includes("B")) counterNumber += "6";
  if (counter.includes("D")) counterNumber += "7";
  if (counter.includes("X")) counterNumber += "8";
  return counterNumber;
}

export const toastErr = (content) => {
  toast.error(content, {
    position: "bottom-right",
    autoClose: 3000,
    closeOnClick: true,
    draggable: true,
    theme: "colored"
  });
}

export const toastSuccess = (content) => {
  toast.success(content, {
    position: "bottom-right",
    autoClose: 2000,
    closeOnClick: true,
    draggable: true,
    theme: "colored"
  });
}

export const toastInfo = (content) => {
  toast.info(content, {
    position: "bottom-right",
    autoClose: 2000,
    closeOnClick: true,
    draggable: true,
    theme: "colored"
  });
}
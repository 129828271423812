import { useTranslation } from 'react-i18next';

import { Dialog, DialogTitle, DialogContent, Toolbar, Typography } from "@mui/material";
import TextsmsIcon from '@mui/icons-material/Textsms';
import { WhatsappShareButton, WhatsappIcon, LineShareButton, LineIcon } from "react-share";

const ShareDialog = ({content, isShareDialogOpen, setShareDialogOpen }) => {
  const { t } = useTranslation();

  return(
    <Dialog open={isShareDialogOpen} onClose={() => setShareDialogOpen(false)}>
      <DialogTitle>
      {t("button.label.share")}
      </DialogTitle>
      <DialogContent>
        <Toolbar disableGutters sx={{ minWidth: 250 }}>
          <Typography component="div" sx={{ flexGrow: 1 }}>
            <WhatsappShareButton url={content} onClick={() => setShareDialogOpen(false)}>
              <WhatsappIcon size={50} round/>
            </WhatsappShareButton>
          </Typography>
          <Typography component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
            <LineShareButton url={content} onClick={() => setShareDialogOpen(false)}>
              <LineIcon size={50} round/>
            </LineShareButton>
          </Typography>
          <Typography component="div" sx={{ flexGrow: 1, textAlign: 'right' }}>
            <a href={`sms:?&body=${encodeURIComponent(content)}`} onClick={() => setShareDialogOpen(false)}>
              <TextsmsIcon sx={{ fontSize: 50 }} />
            </a>
          </Typography>
        </Toolbar>
      </DialogContent>
    </Dialog>
  )
}
export default ShareDialog;
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { MenuListPage } from "../components/MenuListPage";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';
import AdbIcon from '@mui/icons-material/Adb';
import PasswordIcon from '@mui/icons-material/Password';

export const OperationMenuPage = ({ setTitle, setBackToPath }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setTitle(t("menu.item.operation"));
    setBackToPath({ pathName: "/dashboard" });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MenuListPage pages={[
    { label: t("operation.menu.resend"), path: "/operation/search", type: "resend", icon: <ScheduleSendIcon color="primary"/> },
    { label: t("operation.menu.edit"), path: "/operation/search", type: "edit", icon: <EditIcon color="primary"/> },
    { label: t("operation.menu.void"), path: "/operation/search", type: "void", icon: <CancelIcon color="primary"/> },
    { label: t("operation.menu.autoticket"), path: "/operation/auto-ticket", type: "invoiceno", icon: <HdrAutoIcon color="primary"/> },
    { label: t("operation.menu.pekkong"), path: "/operation/search", type: "pekkong", icon: <AdbIcon color="primary"/> },
    { label: t("operation.menu.changepwd"), path: "/operation/change-pwd", type: "drawdate", icon: <PasswordIcon color="primary"/> }
  ]} />;
};

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MenuListPage } from "../components/MenuListPage";
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import ReceiptIcon from '@mui/icons-material/Receipt';
import NumbersIcon from '@mui/icons-material/Numbers';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import BlockIcon from '@mui/icons-material/Block';
import DateRangeIcon from '@mui/icons-material/DateRange';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';

const APP_AGENT = process.env.REACT_APP_AGENT;

export const HistoryMenuPage = ({ setTitle, setBackToPath }) => {
  const { t } = useTranslation();
  const [pages, setPages] = useState();

  useEffect(() => {
    setTitle(t("menu.item.history"));
    setBackToPath({ pathName: "/dashboard" });
    
    if (APP_AGENT === "BR") {
      setPages([
        { label: t("history.menu.date"), path: "/history/search", type: "date", icon: <CalendarMonthIcon color="primary"/> },
        { label: t("history.menu.invoiceno"), path: "/history/search", type: "invoiceno", icon: <ReceiptIcon color="primary"/> },
        { label: t("history.menu.number"), path: "/history/search", type: "number", icon: <NumbersIcon color="primary"/> },
        { label: t("history.menu.drawdate"), path: "/history/search", type: "drawdate", icon: <SportsEsportsIcon color="primary"/> },
        { label: t("history.menu.latest"), path: "/history/search", type: "latest", icon: <FiberNewIcon color="primary"/> },
      ]);
    } else {
      setPages([
        { label: t("history.menu.list"), path: "/history/search", type: "list", icon: <ListAltIcon color="primary"/> },
        { label: t("history.menu.date"), path: "/history/search", type: "date", icon: <CalendarMonthIcon color="primary"/> },
        { label: t("history.menu.invoiceno"), path: "/history/search", type: "invoiceno", icon: <ReceiptIcon color="primary"/> },
        { label: t("history.menu.number"), path: "/history/search", type: "number", icon: <NumbersIcon color="primary"/> },
        { label: t("history.menu.drawdate"), path: "/history/search", type: "drawdate", icon: <SportsEsportsIcon color="primary"/> },
        { label: t("history.menu.latest"), path: "/history/search", type: "latest", icon: <FiberNewIcon color="primary"/> },
        { label: t("history.menu.mend.so"), path: "/history/search", type: "mendso", icon: <ContentPasteIcon color="primary"/> },
        { label: t("history.menu.mend.so.snv"), path: "/history/search", type: "mendsosnv", icon: <ContentPasteGoIcon color="primary"/> },
        { label: t("history.menu.voided"), path: "/history/search", type: "voided", icon: <BlockIcon color="primary"/> },
        { label: t("history.menu.so"), path: "/history/search", type: "so", icon: <ContentPasteOffIcon color="primary"/> },
        { label: t("history.menu.today.auto"), path: "/history/search", type: "todayauto", icon: <HdrAutoIcon color="primary"/> },
        { label: t("history.menu.multi.date"), path: "/history/search", type: "multidate", icon: <DateRangeIcon color="primary"/> },
      ]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MenuListPage pages={pages} />;
};

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { apiHandle, verifyPassword } from "../../hooks/services/apiServices";
import { useAuth } from "../../hooks/useAuth";
import { isPostErrorResult, toastErr, toastInfo, toastSuccess } from "../../utils/utils";

import ShareButton from "../../components/ShareButton";
import ShareDialog from "../../components/ShareDialog";
import { Container, Box, Button, TextField, Toolbar, CircularProgress } from "@mui/material";
import { List, ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { MenuList, MenuItem } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import ShareIcon from '@mui/icons-material/Share';
import LoadingButton from '@mui/lab/LoadingButton';

export const AutoTicketPage = ({ setTitle, setBackToPath }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const [resultContent, setResultContent] = useState();
  const [resultList, setResultList] = useState([]);
  const [invoiceno, setInvoiceno] = useState();
  const [invoicemsg, setInvoicemsg] = useState();
  const [password, setPassword] = useState();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isPwdDialogOpen, setPwdDialogOpen] = useState(false);
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const [isProgressing, setProgressing] = useState(true);
  const [isLoadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setTitle(t("menu.item.operation"));

    if (location.state) {
      setBackToPath({ pathName: "/operation" });
      handleSearchResult();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchResult = () => {
    apiHandle.getAutoTicketSearch({
      token: user.accessToken
    }).then(result => {
      console.log('result', result.data);
      if (result.data) {
        setResultContent(result.data);

        const list = result.data.trim().split("\n");
        let invoiceno = "";
        let htmlStr = "";
        let resultInfoList = [];
        
        list.forEach(item => {
          if (item === "***") {
            resultInfoList.push({invoiceno: invoiceno, htmlStr: htmlStr});
            invoiceno = "";
            htmlStr = "";
          } else {
            if (item.includes("(Auto #")) {
              invoiceno = item.substr(item.indexOf("#") + 1).replace(")", "");
            }
            htmlStr += item + "\n";
          }
        });
        
        if (htmlStr.length > 0) {
          resultInfoList.push({invoiceno: invoiceno, htmlStr: htmlStr});
        }
        setResultList(resultInfoList);
      } else {
        if (result.data === "") toastInfo(t("info.record.not.found"));
        else toastErr(result.data);
      }
      setProgressing(false);

    }).catch(e => {
      setProgressing(false);
      toastErr(e);
    });
  }

  const handleItemClick = (invoiceno, msg) => {
    setInvoiceno(invoiceno);
    setInvoicemsg(msg);
    setDialogOpen(true);
  }

  const handleOptionClose = (option, msg) => {
    setDialogOpen(false);

    switch(option) {
      case "void": // void
        setPwdDialogOpen(true);
        break;
      case "share":
        setShareDialogOpen(true);
        break;
      default:
        break;
    }
  }

  const handlePwdDialogClose = () => {
    setPwdDialogOpen(false);
    setPassword();
  }

  const handleSubmitVoid = () => {
    if (verifyPassword({
      token: user.accessToken,
      password: password
    })) {
      setLoadingButton(true);

      apiHandle.deleteAutoTicket({
        token: user.accessToken,
        invoiceno: invoiceno
      }).then(result => {
        setPwdDialogOpen(false);
        setLoadingButton(false);
        console.log('result', result.data);
        if (isPostErrorResult(result.data)) {
          toastErr(result.data);
        } else {
          toastSuccess(result.data);
          handleSearchResult();
        }
      }).catch(e => {
        setLoadingButton(false);
        toastErr(e);
      });
    } else toastErr(t("error.password.invalid"));
  }

  return (
    <Container component="main" maxWidth="xs">
      { isProgressing ?
        <Box sx={{ marginTop: 3, flexGrow: 1, textAlign: "center" }}>
          <CircularProgress />
        </Box>
      :
        <Box sx={{ marginTop: 1, flexGrow: 1 }}>
          <Toolbar disableGutters>
            <ShareButton content={resultContent}/>
          </Toolbar>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            { resultList?.map((item, index) =>
              <>
                <ListItem key={index} onClick={() => handleItemClick(item.invoiceno, item.htmlStr)}>
                  <ListItemText sx={{color: item.htmlStr.includes("CANCELLED") ? '#ff0000' : '#000000'}}>
                    {item.htmlStr.split("\n").map(str => <div>{str}</div>)}
                  </ListItemText>
                </ListItem>
                <Divider component="li"/>
              </>
            )}
          </List>
        </Box>
      }

      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
        {t("button.label.options")}
        </DialogTitle>
        <DialogContent>
          <MenuList sx={{ minWidth: 250 }}>
            <MenuItem onClick={() => handleOptionClose("void")}>
              <ListItemIcon><CancelIcon fontSize="small" /></ListItemIcon><ListItemText>{t("option.label.void")}</ListItemText>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => handleOptionClose("share")}>
              <ListItemIcon><ShareIcon fontSize="small" /></ListItemIcon><ListItemText>{t("button.label.share")}</ListItemText>
            </MenuItem>
          </MenuList>
        </DialogContent>
      </Dialog>

      <Dialog open={isPwdDialogOpen} onClose={handlePwdDialogClose}>
        <DialogTitle>{t("option.label.void")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("dialog.password.void.desc")}
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePwdDialogClose} color="secondary">{t("button.label.cancel")}</Button>
          {isLoadingButton ? (
            <LoadingButton
              loading
              variant="outlined"
            >
              {t("button.label.void")}
            </LoadingButton>
          ) : (
            <Button onClick={handleSubmitVoid} color="error" variant="contained">
              {t("button.label.void")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <ShareDialog content={invoicemsg} isShareDialogOpen={isShareDialogOpen} setShareDialogOpen={setShareDialogOpen}/>
    </Container>
  );
};
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Container, Box, Button, TextField, Toolbar, Typography } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { Search } from "@mui/icons-material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

export const HistorySearchPage = ({ setTitle, setBackToPath }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchType, setSearchType] = useState();
  const [searchField, setSearchField] = useState();
  const [searchDate, setSearchDate] = useState(new Date());
  const [searchTitle, setSearchTitle] = useState();
  const [searchDesc, setSearchDesc] = useState();

  useEffect(() => {
    setTitle(t("menu.item.history"));
    setBackToPath({ pathName: "/history" });

    if (location.state?.searchTitle && location.state?.searchType) {
      setSearchTitle(location.state.searchTitle);
      setSearchType(location.state.searchType);

      switch (location.state.searchType) {
        case "list":
          setSearchDesc(t("history.search.list"));
          setSearchField("date");
          break;
        case "date":
          setSearchDesc(t("history.search.date"));
          setSearchField("date");
          break;
        case "latest":
          setSearchDesc(t("history.search.latest"));
          setSearchField("text");
          break;
        case "invoiceno":
          setSearchDesc(t("history.search.invoiceno"));
          setSearchField("text");
          break;
        case "number":
          setSearchDesc(t("history.search.number"));
          setSearchField("date-text");
          break;
        case "drawdate":
          setSearchDesc(t("history.search.drawdate"));
          setSearchField("date");
          break;
        case "mendso":
          setSearchDesc(t("history.search.mend.so"));
          setSearchField("date");
          break;
        case "mendsosnv":
          setSearchDesc(t("history.search.mend.so.snv"));
          setSearchField("date");
          break;
        case "voided":
          setSearchDesc(t("history.search.voided"));
          setSearchField("date");
          break;
        case "so":
          setSearchDesc(t("history.search.so"));
          setSearchField("date");
          break;
        case "todayauto":
          setSearchDesc(t("history.search.today.auto"));
          setSearchField("date");
          break;
        case "multidate":
          setSearchDesc(t("history.search.multi.date"));
          setSearchField("date");
          break;
        default:
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDate = (newDate) => {
    setSearchDate(newDate);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    navigate("/history/search-result", {
      state: {
        searchTitle: searchTitle,
        searchType: searchType,
        searchNumber: data.get("number"),
        searchDate: (searchDate && moment(searchDate).format("DDMMYY"))
      }
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box component="form" onSubmit={handleSubmit} sx={{ marginTop: 3, flexGrow: 1 }} noValidate>
        <Toolbar disableGutters>
          { searchField?.includes("date") &&
            <Typography component="div" sx={{ flexGrow: 1 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                  inputFormat="DDMMYY"
                  value={searchDate}
                  onChange={handleChangeDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Typography>
          }
          { searchField?.includes("text") &&
              <TextField
                autoFocus
                name="number"
                sx={{ flexGrow: 1 }}
              />
          }
          <Button
            fullWidth
            type="submit"
            sx={{ flexGrow: 1, padding: '15px 0 15px 0', marginLeft: 1 }}
            variant="contained"
            startIcon={<Search />}
          >
            {t("button.label.search")}
          </Button>
        </Toolbar>
        <Alert severity="info" sx={{ marginTop: 2 }}>
          <AlertTitle>{searchTitle}</AlertTitle>
          {searchDesc}
        </Alert>
      </Box>
    </Container>
  );
};
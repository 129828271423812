import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

import { Container, Box, Grid, Paper, Typography } from "@mui/material";
import { experimentalStyled as styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const MenuIconPage = ({ pages }) => {
  const navigate = useNavigate();
  const { user, logout, logoutMidlet } = useAuth();

  const handleNavigate = (path, title, type) => {
    var state;
    if (title && type) {
      state = {
        state: {
          searchTitle: title,
          searchType: type
        }
      }
    }
    navigate(path, state);
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 3, flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {pages?.map((page, index) => (
            page.path === "logout" ? (
              <Grid item xs={2} sm={4} md={4} key={index}>
                  <Item onClick={user.userAccounts ? logoutMidlet : logout}>
                    {page.icon}
                    <Typography variant="h6" gutterBottom component="div" mt={2}>{page.label}</Typography>
                  </Item>

              </Grid>
            ) : (page.path !== "/dashboard" &&
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Item onClick={() => handleNavigate(page.path, page.label, page.type)}>
                  {page.icon}
                  <Typography variant="h6" gutterBottom component="div" mt={2}>{page.label}</Typography>
                </Item>
              </Grid>
            )
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

import { DataService } from "./dataService";
import CryptoJS from "crypto-js";

const APP_KEY = process.env.REACT_APP_KEY;
const APP_AGENT = process.env.REACT_APP_AGENT;
const APP_AGENT_GOVFEE = process.env.REACT_APP_GOVFEE;

export const apiHandle = {

  userActivation: async (data) => {
    return DataService.post(getRequestString("activateBr", data));
  },

  userActivationDual: async (data) => {
    return DataService.post(getRequestString("activateMidletDual", {
      loginId: data.loginId,
      content: data.content + ",,,false,," + APP_AGENT_GOVFEE
    }));
  },

  userLogin: async (data) => {
    return DataService.post(getRequestString("loginBr", data));
  },

  userLoginDual: async (data) => {
    return DataService.post(getRequestString("loginDual", {
      loginId: data.loginId,
      content: data.content + "," + APP_AGENT_GOVFEE
    }));
  },

  userLoginMidlet: async (data) => {
    return DataService.post(data.content, data.reqEP);
  },

  order: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
    return DataService.post(getRequestString(APP_AGENT === "BR" ? "orderBr" : "order2", {
      loginId: user.id,
      content: getOrderString(data.orderText)
    }), user.reqEP);
  },

  editOrder: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
    return DataService.post(getRequestString(user.type === "A" ? "editOrderAgent" : "editOrder3", {
      loginId: user.id,
      content: data.invoiceno
    }), user.reqEP);
  },

  cancelOrder: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return DataService.post(getRequestString(user.type === "A" ? "cancelOrderAgent" : "cancelOrder", {
      loginId: user.id,
      content: data.invoiceno + ",iOS"
    }), user.reqEP);
  },

  resendOrder: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return DataService.post(getRequestString(user.type === "A" ? "resendOrderAgent" : "resendOrder", {
      loginId: user.id,
      content: data.invoiceno + ",iOS"
    }), user.reqEP);
  },

  changePassword: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return DataService.post(getRequestString("changePassword", {
      loginId: user.id,
      content: data.currpwd + "," + data.newpwd + "," + data.confpwd
    }), user.reqEP);
  },

  getHistorySearch: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return DataService.post(getRequestString(getHistorySearchFunc(data.searchType, user.type), {
      loginId: user.id,
      content: getHistoryString(data)
    }), user.reqEP);
  },

  getWinloseSearch: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
    return DataService.post(getRequestString("getBill_kt", {
      loginId: user.id,
      content: data.dateFrom + "," + data.dateTo + ",own,,END"
    }), user.reqEP);
  },

  getResultSearch: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return DataService.post(getRequestString("getResult4", {
      loginId: user.id,
      content: data.drawDate + (data.counter && data.counter.length > 0 ? "#" + data.counter : "")
    }), user.reqEP);
  },

  getPekkongSearch: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return DataService.post(getRequestString("getPekkong", {
      loginId: user.id,
      content: data.name + "," + data.number
    }), user.reqEP);
  },

  getAnnouncementSearch: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return DataService.post(getRequestString("getAnnouncement", {
      loginId: user.id,
      content: "END"
    }), user.reqEP);
  },

  getHelpSearch: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return DataService.post(getRequestString("getHelp", {
      loginId: user.id,
      content: "END"
    }), user.reqEP);
  },

  getAutoTicketSearch: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return DataService.post(getRequestString("getAutoTicket_apk", {
      loginId: user.id,
      content: ""
    }), user.reqEP);
  },

  deleteAutoTicket: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return DataService.post(getRequestString("deleteAutoTicket", {
      loginId: user.id,
      content: data.invoiceno
    }), user.reqEP);
  },

  getPlayerList: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return DataService.post(getRequestString("getPlayerList", {
      loginId: user.id,
      content: "END"
    }), user.reqEP);
  },

  getInvoiceDetail: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return DataService.post(getRequestString("getHistory", {
      loginId: user.id,
      content: ",," + data.invoiceno
    }), user.reqEP);
  },

  getDrawDayList: async (data) => {
    const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
    const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return DataService.post(getRequestString("getDrawDayList", {
      loginId: user.id,
    }), user.reqEP);
  }
}

export const verifyPassword = (data) => {
  const bytes = CryptoJS.AES.decrypt(data.token, APP_KEY);
  const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
  if (!data.password) return false;
  if (data.password !== user.key) {
    return false;
  }
  return true;
}

// form request string
const getRequestString = (funcName, data) => {
  return funcName + "," + data.loginId + (data.content && "," + data.content);
}

// re-compile order text
const getOrderString = (orderText) => {
  const textArr = orderText.trim().split(/\s+/);
  var reformedOrderText = "";

  textArr.forEach(element => {
    if (element && element !== "") {
      if (element.startsWith("D") && element.endsWith("#")) {
        reformedOrderText += element.replace("D#", "") + ",";
      }else reformedOrderText += element.replace("D", "") + ",";
    }
  });
  return reformedOrderText + "iOS";
}

const getHistorySearchFunc = (searchType, userType) => {
  if (APP_AGENT === "BR") {
    switch (searchType) {
      case "drawdate":
        if (userType === "A") return "getHistory_Draw_Date_AgentApp";
        return "getHistory_Draw_Date_App";
      default:
        if (userType === "A") return "getHistory_AgentApp";
        return "getHistoryApp";
    }
  } else {
    switch (searchType) {
      case "drawdate":
        return "getHistory_Draw_Date";
      case "list":
        return "GetHistory_Invoice_List";
      case "mendso":
        return "GetHistory_Draw_Date_Resendsoldout";
      case "mendsosnv":
        return "GetHistory_Draw_Date_ResendSNV";
      case "voided":
        return "GetHistory_Draw_Date_Void";
      case "so":
        return "GetHistory_Draw_Date_Soldout";
      case "todayauto":
        return "GetHistory_Draw_Date_Autoticket";
      case "multidate":
        return "GetHistory_Draw_Date_Multidate";
      default:
        return "getHistory";
    }
  }
}

const getHistoryString = (data) => {
  switch (data.searchType) {
    case "latest":
      return "," + data.searchNumber;
    case "invoiceno":
      return ",," + data.searchNumber;
    case "number":
      return ",,," + data.searchDate + "#" + data.searchNumber;
    default:
      return data.searchDate;
  }
} 
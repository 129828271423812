import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AppBar } from "./AppBar";

export const ProtectedLayout = ({pages, title, backToPath}) => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <AppBar
        pages={pages}
        title={title}
        backToPath={backToPath}
      />
      {outlet}
    </div>
  );
};

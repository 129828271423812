import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { toastErr } from "../utils/utils";
import { apiHandle } from "../hooks/services/apiServices";

import { Container, Box, TextField, CircularProgress } from "@mui/material";

export const HelpPage = ({ setTitle, setBackToPath }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [searchResult, setSearchResult] = useState();
  const [isProgressing, setProgressing] = useState(true);

  useEffect(() => {
    setTitle(t("menu.item.help"));
    setBackToPath({ pathName: "/dashboard" });
    handleSearchResult();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchResult = () => {
    apiHandle.getHelpSearch({
      token: user.accessToken
    }).then(result => {
      setSearchResult(result.data);
      setProgressing(false);
    }).catch(e => {
      toastErr(e);
      setProgressing(false);
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      { isProgressing ?
        <Box sx={{ marginTop: 3, flexGrow: 1, textAlign: "center" }}>
          <CircularProgress />
        </Box>
      :
        <Box sx={{ marginTop: 3, flexGrow: 1, marginBottom: 3 }}>
          <TextField
            fullWidth
            multiline
            value={searchResult}
            inputProps={
              { readOnly: true, }
            }
          />
        </Box>
      }
    </Container>
  );
};
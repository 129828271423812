import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    fallbackLng: "en",
    lng: "en",
    resources: {
        cn: {
            translations: require('./translations/cn.json')
        },
        en: {
            translations: require('./translations/en.json')
        }
    },
    ns: ["translations"], 
    defaultNS: "translations",
});

export default i18n;
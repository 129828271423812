import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { toastErr } from "../utils/utils";

import { Avatar, Button, TextField, Box, Select, MenuItem } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

export const LoginPage = () => {
  const { login } = useAuth();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useLocalStorage("localization", "en");
  const [errorCallback, setErrorCallBack] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (errorCallback) {
      setLoadingButton(false);
      if (errorCallback.status === "FAILED") toastErr(t("error.login.invalid"));
      else toastErr(errorCallback.message);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCallback]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingButton(true);
    const data = new FormData(event.currentTarget);
    login({
      user: data.get("user"),
      password: data.get("password")
    }, setErrorCallBack);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("login.title")}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Select
            fullWidth
            id="language"
            value={language}
            name="language"
            onChange={(e) => setLanguage(e.target.value)}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="cn">Chinese</MenuItem>
          </Select>
          <TextField
            margin="normal"
            required
            fullWidth
            id="user"
            label={t('login.label.user')}
            name="user"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('login.label.password')}
            type="password"
            id="password"
          />
          {loadingButton ?
            <LoadingButton
              fullWidth
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("button.label.loading")}
            </LoadingButton>
          :
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("button.label.login")}
            </Button>
          }
        </Box>
      </Box>
    </Container>
  );
};

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { toastErr, toastSuccess } from "../../utils/utils";

import { Avatar, Button, TextField, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import PasswordIcon from '@mui/icons-material/Password';
import { apiHandle } from "../../hooks/services/apiServices";

export const ChangePasswordPage = ({ setTitle, setBackToPath }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);
  
  useEffect(() => {
    setTitle(t("menu.item.operation"));
    setBackToPath({ pathName: "/operation" });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingButton(true);
    const data = new FormData(event.currentTarget);
    const newPwd = data.get("newpwd");
    const confPwd = data.get("confpwd");

    if (newPwd !== confPwd) {
      toastErr(t("error.password.not.equal"));
      setLoadingButton(false);
      return;
    }
    
    apiHandle.changePassword({
      token: user.accessToken,
      currpwd: data.get("currpwd"),
      newpwd: newPwd,
      confpwd: confPwd
    }).then(result => {
      setLoadingButton(false);

      if (result.data === "Successful") {
        toastSuccess(result.data);
      } else toastErr(result.data);
      
    }).catch(e => {
      setLoadingButton(false);
      toastErr(e);
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <PasswordIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("operation.menu.changepwd")}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="currpwd"
            label={t('changepwd.label.currpwd')}
            type="password"
            name="currpwd"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="newpwd"
            label={t('changepwd.label.newpwd')}
            type="password"
            name="newpwd"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confpwd"
            label={t('changepwd.label.confpwd')}
            type="password"
            id="confpwd"
          />
          {loadingButton ?
            <LoadingButton
              fullWidth
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("button.label.loading")}
            </LoadingButton>
          :
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("button.label.changepwd")}
            </Button>
          }
        </Box>
      </Box>
    </Container>
  );
};
